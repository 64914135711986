
import { defineComponent, ref, reactive, computed, watch, watchEffect } from "vue"
import { IonItem, IonSelect, IonSelectOption } from '@ionic/vue'
import store from '@/store'
import { makePortalOptions, makePortalStructure } from '@/types/portal'
import { makeEntity, Entity } from '@/types/people'
import { BackendApi, getErrorText } from '@/utils/http'
import LoadingGif from './Controls/LoadingGif.vue'

export default defineComponent({
  name: 'EntitySelect',
  components: {LoadingGif, IonItem, IonSelect, IonSelectOption, },
  emits: {
    change: (entity: Entity) => entity,
  },
  setup(props, { emit, }) {
    const entities = ref([])
    const entity: Entity|null = null
    const selection = reactive({ entity: entity, })
    const loadingName = 'inscriptions-tab'
    let userEmail = ''

    const loadUserConfig = async function() {
      const backendApi = new BackendApi('get', '/portal/api/user-config/')
      store.commit('startLoading', loadingName)
      selection.entity = null
      try {
        const resp = await backendApi.callApi()
        entities.value = resp.data['youth_home_entities'].map((elt: any) => makeEntity(elt))
        if (entities.value.length === 1) {
          selection.entity = entities.value[0]
        }
        store.commit('setOptions', makePortalOptions(resp.data.options))
        store.commit('setStructure', makePortalStructure(resp.data.structure))
      } catch (err) {
        await store.dispatch('addError', getErrorText(err))
      }
      store.commit('endLoading', loadingName)
    }

    const loadEntities = async function() {
      if (store.getters.entities.length === 0) {
        await loadUserConfig()
        store.commit('setEntities', entities.value)
        store.commit('setSelectedEntity', selection.entity)
      } else {
        entities.value = store.getters.entities
        selection.entity = store.getters.selectedEntity
        const entity: Entity|null = selection.entity
        if (entity !== null) {
          emit('change', entity)
        }
      }
    }

    const isLoading = computed(() => {
      return !!store.getters.loading[loadingName]
    })

    const newUserEmail = computed(() => { return store.getters.userEmail })

    watchEffect(
      () => {
        if (newUserEmail.value !== userEmail) {
          userEmail = newUserEmail.value
          if (newUserEmail.value) {
            loadEntities()
          }
        }
      }
    )

    watch(
      // this is the source of the watcher
      () => selection.entity,
      // this is the callback called when the source changes
      (entity: Entity|null) => {
        if (entity !== null) {
          emit('change', entity)
        }
        store.commit('setSelectedEntity', entity)
      },
      // a watcher with a source is lazy by default
      // whereas a watcher with just an effect and no source is not // but we can make it run immediately with this option
      { immediate: true, }
    )

    return { entities, loadingName, selection, isLoading, }
  },
})
