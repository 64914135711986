
import { defineComponent, computed } from 'vue'
import store from '@/store'

export default defineComponent({
  name: 'MessageBar',

  setup() {
    const messages = computed(() => {
      return store.getters.messages
    })
    return { messages, }
  },
})
