import { User, makeUser, BackendInstance } from '@/types/auth'
import axios from 'axios'
import store from '@/store'
import { getBackends } from '@/apis/settings'
import { htmlText } from '@/utils/html'
import { getErrorText } from '@/utils/http'

export const BackendMixin = {
  methods: {

    getFullBackendUrl(url: string): string {
      return store.getters.backendUrl + url
    },

    getFrontendSetting(setting: string): string {
      if (store.getters.frontendInstance) {
        return store.getters.frontendInstance.getFrontendSetting(setting)
      }
      return ''
    },

    getFrontendStructureTitle(): string {
      if (store.getters.frontendInstance) {
        return htmlText(store.getters.frontendInstance.structureTitle)
      }
      return ''
    },

    getFrontendStructureInfo(): string {
      if (store.getters.frontendInstance) {
        return htmlText(store.getters.frontendInstance.structureInfo)
      }
      return ''
    },

    getFrontendStructureLogo(): string {
      if (store.getters.frontendInstance && store.getters.frontendInstance.logo) {
        return store.getters.frontendInstance.backend.url + store.getters.frontendInstance.logo
      }
      return ''
    },

    login(backend: BackendInstance, email: string, password: string): Promise<User> {
      return new Promise(
        (resolve, reject) => {
          const fullUrl: string = backend.url + '/users/api/login/'
          axios({
            url: fullUrl,
            data: { email, password, },
            method: 'POST',
          }).then(async(resp: any) => {
            if (!resp.data.success) {
              reject(Error(resp.data.message))
            } else {
              const user: User|null = makeUser(resp.data, backend)
              await store.dispatch('setUser', user)
              if (user) {
                resolve(user)
              } else {
                reject(Error('Utilisateur inconnu'))
              }
            }
          }).catch(err => {
            reject(err)
          })
        }
      )
    },

    logout(): Promise<boolean> {
      return new Promise(
        (resolve) => {
          store.dispatch('resetUser').then(
            () => resolve(true)
          )
        }
      )
    },

    getBackends(email: string): Promise<BackendInstance[]> {
      return getBackends(email)
    },

    getErrorText(err: any): string {
      return getErrorText(err)
    },
  },
}
