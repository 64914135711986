
import { defineComponent, ref, computed } from 'vue'
import store from '@/store'

export default defineComponent({
  name: 'LoadingGif',

  props: {
    loadingName: {
      type: String,
    },
    short: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const wait = ref(false)

    const loading = computed(() => {
      return store.getters.loading
    })

    const isLoading = computed(() => {
      if (store.getters.loading && props.loadingName) {
        return !!store.getters.loading[props.loadingName]
      }
      return false
    })

    function show() {
      wait.value = true
      setTimeout(() => {
        wait.value = false
      }, 200)
    }

    show()

    return { isLoading, wait, loading, }
  },
})
