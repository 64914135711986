
function escapeHtml(unsafe: string) {
  return unsafe.replace(
    /&/g, '&amp;'
  ).replace(
    /</g, '&lt;'
  ).replace(
    />/g, '&gt;'
  ).replace(
    /"/g, '&quot;'
  ).replace(
    /'/g, '&#039;'
  )
}

export function htmlText(text: string) {
  const escapedMessage = escapeHtml(text)
  return escapedMessage.split('\n').join('<br />')
}
