
export function storeFranceConnectData(data: any) {
  window.localStorage.setItem('fc-state', data.state)
  window.localStorage.setItem('fc-nonce', data.nonce)
}

export function loadFranceConnectData(): any {
  return {
    state: window.localStorage.getItem('fc-state') || '',
    nonce: window.localStorage.getItem('fc-nonce') || '',
  }
}

export function storeFranceConnectLogout(logoutUrl: string) {
  window.localStorage.setItem('fc-logout', logoutUrl)
}

export function loadFranceConnectLogout(): string {
  return window.localStorage.getItem('fc-logout') || ''
}
