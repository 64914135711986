<template>
  <div class="top-bar" v-if="isAuthenticated">
    <ion-grid>
      <ion-row>
        <ion-col>
          <div class="top-bar-title">
            <img
              src="@/assets/img/millibase-icon.svg"
              style="width: 48px; display: inline-block; vertical-align: top;"
            />
            millibase.net
          </div>
        </ion-col>
        <ion-col>
          <div class="text-center">{{ backendName }}</div>
        </ion-col>
        <ion-col>
          <div class="text-right">
            <div class="user-name">{{ userName }}</div>
            <div class="user-logout">
              <a href v-on:click="onLogout">Déconnexion</a>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { BackendMixin } from '@/mixins/backend'
import { IonRow, IonCol, IonGrid } from '@ionic/vue'
import { loadFranceConnectLogout, storeFranceConnectLogout } from '@/types/france_connect'

export default {
  name: 'TopBar',
  mixins: [BackendMixin],
  props: {
    version: String,
  },
  components: {
    IonGrid, IonRow, IonCol,
  },
  computed: {
    userName() {
      return store.getters.userName
    },
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    backendName() {
      return store.getters.backendName
    },
  },
  methods: {
    onLogout(evt) {
      evt.preventDefault()
      this.logout().then(() => {
        router.push({ name: 'login', }).then(
            () => {
              const logoutUrl = loadFranceConnectLogout()
              if (logoutUrl) {
                storeFranceConnectLogout('') // reset data
                window.location.href = logoutUrl
              } else {
                window.location.reload()
              }
          }
        )
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top-bar-title {
  font-size: 16px;
  padding: 2px 10px;
}
.text-right {
  text-align: right;
}
.top-bar {
  background: var(--ion-color-light-tint);
  border-bottom: solid 2px var(--ion-color-dark);
}
</style>
